import { BookStructure, Character, PrintAtHome } from '@/services/models';
import React from 'react';
import Button, { RoundButton } from '@/components/shared/Button';
import { Icon } from '@/components/shared/Icon';
import { RootStateOrAny, useSelector, useDispatch } from 'react-redux';
import PahItemPreview from './PahItemPreview';
import { setPahAssetCode } from '@/store/actions/printAtHome';
import { setCurrentStepPah } from '@/store/actions/currentStepPah';
import { populatePrintAtHome } from '@/store/initializeState';
import { useViewport } from '@/hooks/useViewport';

type Props = {
  character: Character;
  step: number;
  border?: boolean;
  clientName?: string | null;
  selectionButtons?: boolean;
};

const PahPreview: React.FC<Props> = ({
  character,
  border = false,
  clientName,
  selectionButtons = true,
  step
}) => {
  const { isMobile } = useViewport();

  const dispatch = useDispatch();
  const { printAtHome } = useSelector(
    (state: { printAtHome: PrintAtHome }) => state
  );
  const { pahKeys } = useSelector((state: RootStateOrAny) => state);

  const [currentPage, setCurrentPage] = React.useState(0);
  const isLeftDisabled = () => currentPage - 1 < 0;
  const isRightDisabled = () => currentPage + 2 > pahKeys.length;
  const onPrevious = () => setCurrentPage(currentPage - 1);
  const onNext = () => setCurrentPage(currentPage + 1);

  React.useEffect(() => {
    if (printAtHome.assetCode) {
      if (!pahKeys) {
        populatePrintAtHome(dispatch);
      }
      const index = pahKeys.findIndex(
        (pahKey: BookStructure) => pahKey.keyName === printAtHome.assetCode
      );
      console.log({ index });

      setCurrentPage(index);
    }
  }, [character, clientName, pahKeys]);

  if (!pahKeys) return <></>;

  return (
    <div
      aria-label="Book Preview Mobile"
      className="flex flex-col flex-grow max-w-9/10"
    >
      <div
        className="flex flex-grow"
        style={{
          maxHeight:
            step === 1 || step === 3
              ? 'calc(100vh - 384px)'
              : isMobile // eslint-disable-next-line indent
              ? 'calc(100vh - 460px)' // eslint-disable-next-line indent
              : 'calc(100vh - 522px)'
        }}
      >
        {pahKeys[currentPage] &&
          pahKeys[currentPage].keyName && [
            <div className="flex flex-grow" key={pahKeys[currentPage].keyName}>
              <PahItemPreview
                pahKey={pahKeys[currentPage]}
                character={character}
              />
            </div>
            // eslint-disable-next-line indent
          ]}
      </div>
      {selectionButtons && (
        <div className="flex flex-row pt-4 justify-center">
          <div className="flex w-357 justify-between">
            <div className="flex">
              <RoundButton
                onClick={onPrevious}
                disabled={isLeftDisabled()}
                color={isLeftDisabled() ? 'gray-300' : undefined}
                aria-label="previous"
              >
                <Icon icon="arrow-left" color="white" />
              </RoundButton>
              <RoundButton
                onClick={onNext}
                disabled={isRightDisabled()}
                color={isRightDisabled() ? 'gray-300' : undefined}
                aria-label="next"
              >
                <Icon icon="arrow-right" color="white" />
              </RoundButton>
            </div>
            <div className="flex">
              <Button
                className="w-full xl:w-48 h-14 border px-0 py-1 border-primary "
                color="primary"
                onClick={() => {
                  dispatch(setCurrentStepPah(2));
                  dispatch(setPahAssetCode(pahKeys[currentPage].keyName)); // pahKeys[currentPage].keyName
                }}
                aria-label="SELECT THIS DESIGN"
                style={{ float: 'right' }}
              >
                <span className="font-bold text-14">SELECT THIS DESIGN</span>
              </Button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PahPreview;
