import React from 'react';
import Typography from '@/components/shared/Typography';
import Button from '@/components/shared/Button';
import Input from '@/components/shared/Input';
import StepTitle from '../StepTitle';
import { useDispatch, useSelector, RootStateOrAny } from 'react-redux';
import { setPahName, setPahEmail } from '@/store/actions/printAtHome';
import { PrintAtHome } from '@/services/models';
import PahPreview from '@/components/PrintAtHome/PahPreview';
import { sendDesign } from '@/services/printAtHome.service';

export type SecondStepProps = {
  setPahStep: (step: number) => void;
  setShowPahModal: React.Dispatch<React.SetStateAction<boolean>>;
};

export type StepTitleProps = any;

const NUMBER_OF_CHARACTERS_ALLOWED = 11;

const SecondStep: React.FC<SecondStepProps> = ({
  setPahStep,
  setShowPahModal
}) => {
  const dispatch = useDispatch();
  const { characterPreview } = useSelector((state: RootStateOrAny) => state);
  const { printAtHome } = useSelector(
    (state: { printAtHome: PrintAtHome }) => state
  );

  const onChangeName = (name: string) => {
    // Makes the first letter uppercase
    let name_ = name
      .split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');

    if (name_.length > NUMBER_OF_CHARACTERS_ALLOWED) {
      name_ = name_.substring(0, NUMBER_OF_CHARACTERS_ALLOWED);
    }

    // Removes diacritics from name
    name_ = name_.normalize('NFD').replace(/[\u0300-\u036f]/g, '');

    dispatch(setPahName(name_));
  };

  const onChangeEmail = (email: string) => {
    dispatch(setPahEmail(email));
  };

  return (
    <div className="flex flex-col items-center w-auto sm:w-auto pb-6 pt-0 px-6">
      {/* BREADCRUMBS */}
      <div>
        <StepTitle
          step={2}
          setPahStep={setPahStep}
          setShowPahModal={setShowPahModal}
        />
      </div>

      {/* TITLE */}
      <div className="mt-4 mb-2">
        <Typography type="h1" className="xs:h1">
          Print at home
        </Typography>
      </div>

      {/* PREVIEW */}
      <div className="flex flex-grow flex-col w-full">
        <PahPreview
          character={characterPreview}
          clientName={printAtHome.name}
          selectionButtons={false}
          border={true}
          step={2}
        />
      </div>

      {/* TEXT */}
      <div className="mt-2 text-center mb-2">
        <Typography type="b2">
          Great job!
          <br />
          Let us know where we can send your design below!
        </Typography>
      </div>

      {/* INPUT: NAME */}
      <div className="mt-0 w-3/4 max-w-sm text-center">
        <Input
          className="w-full h-8 xl:h-16"
          textSize="20"
          placeholder="Parent's first name"
          onChange={e => onChangeName(e.target.value)}
          value={printAtHome?.name ? printAtHome.name : ''}
          style={{
            border: '1px solid #6BBCFF'
          }}
        />
      </div>

      {/* INPUT: EMAIL */}
      <div className="mt-2 w-3/4 max-w-sm mb-0">
        <Input
          className="w-full h-8 xl:h-16"
          textSize="20"
          placeholder="Parent's email"
          onChange={e => onChangeEmail(e.target.value)}
          value={printAtHome?.email ? printAtHome.email : ''}
          style={{
            border: '1px solid #6BBCFF'
          }}
        />
      </div>

      {/* ACTION BUTTONS */}
      {/* <div className="xl:flex mt-2 xl:flex-row-reverse">
        <Button
          className="w-81 h-14 xl:ml-2 mt-2"
          onClick={() => {
            sendDesign({ printAtHome, character: characterPreview });
            setPahStep(3);
          }}
          aria-label="Receive design"
          disabled={!printAtHome.name || !printAtHome.email}
        >
          <span className="font-bold text-14 uppercase">Receive design</span>
        </Button>
        <Button
          className="w-81 h-14 mt-2"
          onClick={() => setPahStep(1)}
          aria-label="Choose another design!"
          color="white"
          textColor="primary"
          style={{
            border: '2px solid #6BBCFF'
          }}
        >
          <span className="font-bold text-14 uppercase">
            Choose another design!
          </span>
        </Button>
        </div> */}

      {/* ACTION BUTTONS - DESKTOP */}
      <div className="flex mt-2 hidden xl:inline-flex">
        <Button
          className="w-81 h-14 mt-2"
          onClick={() => setPahStep(1)}
          aria-label="Choose another design!"
          color="white"
          textColor="primary"
          style={{
            border: '2px solid #6BBCFF'
          }}
        >
          <span className="font-bold text-14 uppercase">
            Choose another design!
          </span>
        </Button>
        <Button
          className="w-81 h-14 xl:ml-2 mt-2"
          onClick={() => {
            sendDesign({ printAtHome, character: characterPreview });
            setPahStep(3);
          }}
          aria-label="Receive design"
          disabled={!printAtHome.name || !printAtHome.email}
        >
          <span className="font-bold text-14 uppercase">Receive design</span>
        </Button>
      </div>

      {/* ACTION BUTTONS - MOBILE */}
      <div className="flex flex-col mt-2 xl:hidden">
        <Button
          className="w-81 h-14 xl:ml-2 mt-2"
          onClick={() => {
            sendDesign({ printAtHome, character: characterPreview });
            setPahStep(3);
          }}
          aria-label="Receive design"
          disabled={!printAtHome.name || !printAtHome.email}
        >
          <span className="font-bold text-14 uppercase">Receive design</span>
        </Button>
        <Button
          className="w-81 h-14 mt-2"
          onClick={() => setPahStep(1)}
          aria-label="Choose another design!"
          color="white"
          textColor="primary"
          style={{
            border: '2px solid #6BBCFF'
          }}
        >
          <span className="font-bold text-14 uppercase">
            Choose another design!
          </span>
        </Button>
      </div>
    </div>
  );
};

export default SecondStep;
