import React from 'react';
import Typography from '@/components/shared/Typography';
import Button from '@/components/shared/Button';
import StepTitle from '../StepTitle';
import { useSelector, RootStateOrAny } from 'react-redux';
import { PrintAtHome } from '@/services/models';
import router from 'next/router';
import PahPreview from '@/components/PrintAtHome/PahPreview';

export type ThirdStepProps = {
  setPahStep: (step: number) => void;
  setShowPahModal: React.Dispatch<React.SetStateAction<boolean>>;
};

export type StepTitleProps = any;

const ThirdStep: React.FC<ThirdStepProps> = ({
  setPahStep,
  setShowPahModal
}) => {
  const { characterPreview } = useSelector((state: RootStateOrAny) => state);
  const { printAtHome } = useSelector(
    (state: { printAtHome: PrintAtHome }) => state
  );

  return (
    <div className="flex flex-col items-center w-auto sm:w-auto pb-0 pt-0 px-0">
      {/* BREADCRUMBS */}
      <div>
        <StepTitle
          step={2}
          setPahStep={setPahStep}
          setShowPahModal={setShowPahModal}
        />
      </div>

      {/* TITLE */}
      <div className="mt-2">
        <Typography type="h1" className="xs:h1">
          File sent!
        </Typography>
      </div>

      {/* PREVIEW */}
      <div className="flex flex-grow flex-col w-full">
        <PahPreview
          character={characterPreview}
          clientName={printAtHome.name}
          selectionButtons={false}
          border={true}
          step={3}
        />
      </div>

      {/* TEXT */}
      <div className="mt-2 text-center">
        <Typography type="b2">
          Congratulations! Check your email for the design or explore our books!
        </Typography>
      </div>

      {/* ACTION BUTTONS - DESKTOP */}
      <div className="flex mt-2 hidden xl:inline-flex">
        <Button
          className="w-81 h-14   mx-auto"
          onClick={() => setPahStep(1)}
          aria-label="Choose another design!"
          color="white"
          textColor="primary"
          style={{
            border: '2px solid #6BBCFF'
          }}
        >
          <span className="font-bold text-14 uppercase">
            Choose another design!
          </span>
        </Button>
        <Button
          className="w-81 h-14 xl:ml-2 mb-2 xl:mb-0"
          onClick={() => {
            setShowPahModal(false);
            router.push('/shop');
          }}
          aria-label="Explore books"
        >
          <span className="font-bold text-14 uppercase">Explore books</span>
        </Button>
      </div>
      <div className="flex flex-col mt-2 xl:hidden">
        <Button
          className="w-81 h-14 xl:ml-2 mb-2 xl:mb-0"
          onClick={() => {
            setShowPahModal(false);
            router.push('/shop');
          }}
          aria-label="Explore books"
        >
          <span className="font-bold text-14 uppercase">Explore books</span>
        </Button>
        <Button
          className="w-81 h-14   mx-auto"
          onClick={() => setPahStep(1)}
          aria-label="Choose another design!"
          color="white"
          textColor="primary"
          style={{
            border: '2px solid #6BBCFF'
          }}
        >
          <span className="font-bold text-14 uppercase">
            Choose another design!
          </span>
        </Button>
      </div>
    </div>
  );
};

export default ThirdStep;
