import React from 'react';
import Typography from '@/components/shared/Typography';
import PahPreview from '@/components/PrintAtHome/PahPreview';
import StepTitle from '../StepTitle';
import { useSelector, RootStateOrAny } from 'react-redux';

export type FirstStepProps = {
  setPahStep: (step: number) => void;
  setShowPahModal: React.Dispatch<React.SetStateAction<boolean>>;
};

const FirstStep: React.FC<FirstStepProps> = ({
  setPahStep,
  setShowPahModal
}) => {
  const { characterPreview } = useSelector((state: RootStateOrAny) => state);

  return (
    <div className="flex flex-col h-full items-center pb-6 px-6">
      <div>
        <StepTitle
          step={1}
          setPahStep={setPahStep}
          setShowPahModal={setShowPahModal}
        />
      </div>
      <div className="mt-4">
        <Typography type="h1" className="xs:h1">
          Print at home
        </Typography>
      </div>
      <div className="text-center pb-4 xl:pb-2 xl:w-576 mt-2">
        <Typography type="b2">
          Choose your free print at home design!
          <br />
          It will be emailed to you immediately!
        </Typography>
      </div>
      <div className="flex flex-grow flex-col w-full">
        <PahPreview character={characterPreview} step={1} />
      </div>
    </div>
  );
};

export default FirstStep;
