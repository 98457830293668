import React from 'react';
import cx from 'classnames';
import RightArrow from '@/components/shared/Icon/ReactIcons/RightArrow';
import CloseIcon from '@/components/shared/Icon/ReactIcons/CloseIcon';
import { useViewport } from '@/hooks/useViewport';

export type StepTitleProps = {
  step?: number;
  setPahStep: (step: number) => void;
  setShowPahModal: React.Dispatch<React.SetStateAction<boolean>>;
};

const StepTitle: React.FC<StepTitleProps> = ({
  step = 1,
  setPahStep,
  setShowPahModal,
  ...props
}) => {
  const viewport = useViewport();

  return (
    <div className="w-screen px-6 items-center flex justify-center relative">
      <div
        className="absolute h-10 w-10 rounded-full shadow-card border-2 border-gray-100 flex justify-center items-center cursor-pointer"
        style={{
          right: viewport.isDesktop ? 'calc(50% - 470px)' : 20,
          top: -12
        }}
        onClick={() => setShowPahModal(false)}
      >
        <CloseIcon />
      </div>
      <div className="xl:hidden flex flex-row justify-between w-full items-center">
        <div
          className={cx(
            'flex justify-center items-center rounded-full bg-lightDifGreen w-24 h-6'
          )}
          style={{ marginLeft: 'calc(50% - 48px)' }}
          {...props}
        >
          <p className={cx('text-xs text-difGreen font-extrabold uppercase')}>
            step {step} of 2
          </p>
        </div>
      </div>

      <div className={cx('hidden xl:flex xl:flex-row xl:relative')}>
        <div
          className={cx({
            'flex justify-center items-center rounded-full bg-lightDifGreen w-105 h-6 border border-difGreen':
              step === 1
          })}
          {...props}
        >
          <p
            className={cx('font-bold text-14 text-basicGray', {
              'xl:text-primary': step === 1
            })}
          >
            <div className="cursor-pointer" onClick={() => setPahStep(1)}>
              DESIGN
            </div>
          </p>
        </div>

        <div className={cx('flex justify-center items-center')}>
          <p className={cx('mx-14')}>
            {' '}
            <RightArrow />{' '}
          </p>
        </div>

        <div
          className={cx({
            'flex justify-center items-center rounded-full bg-lightDifGreen w-87 h-6 border border-difGreen':
              step === 2
          })}
          {...props}
        >
          <p
            className={cx('font-bold text-14 text-basicGray', {
              'xl:text-primary': step === 2
            })}
          >
            <div className="cursor-pointer" onClick={() => setPahStep(2)}>
              EMAIL
            </div>
          </p>
        </div>
      </div>
    </div>
  );
};

export default StepTitle;
