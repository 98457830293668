import { processCharactersAssetsURLs } from '@/services/characterProcessor.service';
import { Character, BookStructure } from '@/services/models';
import React from 'react';

import { detect } from 'detect-browser';

type Props = {
  pahKey: BookStructure;
  character: Character;
};

const PahItemPreview: React.FC<Props> = ({ pahKey, character }) => {
  console.log('PahItemPreview', { pahKey, character });
  const page = pahKey.pages[0];
  console.log({ c: page.characters });

  // const textBox = page.textBoxes[0];
  const browser = detect();
  console.log('browser:', browser);

  return (
    <div
      className="flex flex-grow"
      style={{
        maxWidth: pahKey.spreadPageSize.width
      }}
    >
      <div
        className="flex flex-grow border-dashed justify-center"
        style={{ flexBasis: 'fit-content' }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox={`0 0 ${pahKey.spreadPageSize.width} ${pahKey.spreadPageSize.height}`}
          key={pahKey.keyName}
          className="border-4 border-primary p-2 border-dashed"
          style={{
            width:
              browser?.name === 'safari' || browser?.os === 'iOS'
                ? '100%'
                : undefined
          }}
        >
          {/* https://kst-dev-books-original-pages.s3.amazonaws.com/print-at-home/room-cheetah.png */}
          {/* Background */}
          <image
            href={page.backgroundImage.replace(
              'print-at-home/',
              'print-at-home/preview/'
            )}
            x="0"
            y="0"
            height={pahKey.spreadPageSize.height}
            width={pahKey.spreadPageSize.width}
            onError={({ target }: any) => {
              target.href.baseVal =
                'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=';
            }}
          />

          {/* Character */}
          {page.characters.map((keyCharacter, i) => {
            const section = pahKey.pages[0].characters[i];
            const characterUrls = processCharactersAssetsURLs(
              character.currentSelection,
              section
            );
            // console.log({ characterUrls });

            return characterUrls.map((url, j) => {
              if (url.indexOf('none') === -1) {
                return (
                  <image
                    key={`sectionImage-${i}-${j}`}
                    href={url}
                    x={section.position.x}
                    y={section.position.y}
                    height={section.size.height}
                    width={section.size.width}
                    onError={({ target }: any) => {
                      target.href.baseVal =
                        'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=';
                    }}
                  />
                );
              }

              return null;
            });
          })}

          {/* Text box */}
          {character.name &&
            page.textBoxes.map((textBox, i) => {
              const horizontalAlignment = {
                left: 'start',
                right: 'end',
                center: 'middle'
              }[textBox.horizontalAlignment];

              const processedXAnchor =
                {
                  start: textBox.position?.x,
                  end: textBox.position?.x + textBox.size.width,
                  middle: textBox.position?.x + textBox.size.width / 2
                }[horizontalAlignment] || 0;

              return (
                <text
                  key={i}
                  x={processedXAnchor}
                  y={textBox.position.y + textBox.fontSize / 1.2}
                  fill={textBox.fontColor}
                  style={{
                    fontSize: textBox.fontSize,
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-expect-error
                    textAnchor: horizontalAlignment,
                    fontFamily: textBox.fontFamily,
                    color: textBox.fontColor,
                    fontStyle: textBox.fontStyle
                      ? textBox.fontStyle
                      : undefined,
                    fontWeight: textBox.fontWeight
                      ? textBox.fontWeight
                      : undefined
                  }}
                >
                  {character.name}
                  {"'s"}
                </text>
              );
            })}
        </svg>
      </div>
    </div>
  );
};

export default PahItemPreview;
